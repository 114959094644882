// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.6); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
}

.loader span {
    text-align: center;
    position: absolute;
    font-family:'Courier New', Courier, monospace;
    font-size: small;
    font-weight: 500;
    font-style: italic;
    color: #d6caca;
}

.loader span p {
    margin-top: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,kCAAkC;IAClC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,6CAA6C;IAC7C,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".loader {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0,0,0, 0.6); \n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000; \n}\n\n.loader span {\n    text-align: center;\n    position: absolute;\n    font-family:'Courier New', Courier, monospace;\n    font-size: small;\n    font-weight: 500;\n    font-style: italic;\n    color: #d6caca;\n}\n\n.loader span p {\n    margin-top: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
