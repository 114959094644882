// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.class-title {
    display: flex;
    padding: 0 0 0 1em;
    font-size: 50px;
    font-weight: 600;
    background-image: linear-gradient(to right, rgb(75, 122, 223), rgb(110, 200, 255));
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    font-family: 'Courier New', Courier, monospace;
    text-shadow: 0px 4px 10px rgba(236, 226, 245, 0.3)
}

.class-text {
    color: rgb(75, 122, 223);
    display: flex;
    font-family: 'Courier New', Courier, monospace;
    font-weight: normal;
    font-size: 0.8em;
    padding-left: 3em;
    margin-top: 0%;
    margin-bottom: 5%;
}`, "",{"version":3,"sources":["webpack://./src/containers/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,kFAAkF;IAClF,kBAAkB;IAClB,qBAAqB;IACrB,6BAA6B;IAC7B,8CAA8C;IAC9C;AACJ;;AAEA;IACI,wBAAwB;IACxB,aAAa;IACb,8CAA8C;IAC9C,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".class-title {\n    display: flex;\n    padding: 0 0 0 1em;\n    font-size: 50px;\n    font-weight: 600;\n    background-image: linear-gradient(to right, rgb(75, 122, 223), rgb(110, 200, 255));\n    color: transparent;\n    background-clip: text;\n    -webkit-background-clip: text;\n    font-family: 'Courier New', Courier, monospace;\n    text-shadow: 0px 4px 10px rgba(236, 226, 245, 0.3)\n}\n\n.class-text {\n    color: rgb(75, 122, 223);\n    display: flex;\n    font-family: 'Courier New', Courier, monospace;\n    font-weight: normal;\n    font-size: 0.8em;\n    padding-left: 3em;\n    margin-top: 0%;\n    margin-bottom: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
