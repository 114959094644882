import React from 'react';
import './GridElement.css'
import 'font-awesome/css/font-awesome.min.css'



export class GridElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: props.element,
            orgs: 0,
            locations: 0,
            persons: 0,
            orgs_list: null,
            locations_list: null,
            persons_list: null,
            show_stats: false,
            disabled: false,
            collapsed: false
        }

    }

    handle_article_stats = (d) => {
        const url = `https://mediapot.iit.demokritos.gr:2005/graph/graph-subset/Title/${d.connected_node_name}`
        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({
                orgs: data.connected_node_labels["Org"],
                locations: data.connected_node_labels["Location"],
                persons: data.connected_node_labels["Person"],
                locations_list: data.connected_node_labels["locations"],
                persons_list: data.connected_node_labels["persons"],
                orgs_list: data.connected_node_labels["orgs"],
                show_stats: true, disabled: true
            }))
    }

    toggle_collapse = () => {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    render() {
        const element = this.state.element
        return (
            <div className="grid-element">
                <div className="card">
                    <div className="card-title">
                        <a href={element.connected_node_url} target='_blank'>{element.connected_node_name}</a>
                    </div>
                    <div className='card-date'>
                        <i className="fa fa-calendar" />  {element.date}
                        <i className="fa fa-clock-o" />  {element.time}
                    </div>
                    <div className='card-relationship'>Connected Relationship <i className="fa fa-exchange" /> : {element.connected_node_label} <p>{element.relationship_type}</p> ({element.name})
                    </div>
                    <button className='card-analyze' onClick={() => this.handle_article_stats(element)} disabled={this.state.disabled}>
                        <i className="fa fa-search"> Analyze Article</i>
                    </button>
                    {this.state.show_stats && <div className='article-stats'>
                        <p>Article labels found: 
                        {this.state.collapsed ? <i className='fa fa-angle-double-down' onClick={this.toggle_collapse}/> : <i className='fa fa-angle-double-up' onClick={this.toggle_collapse}/>}
                        </p>
                        
                        {!this.state.collapsed &&
                        <div>
                            <i className="fa fa-map-marker" >
                                <p>Locations:  {this.state.locations}</p>
                                <ul>
                                    {this.state.locations_list.map((k, i) => <li key={i}>{k}</li>)}
                                </ul>
                            </i>
                        </div>}
                        {!this.state.collapsed &&
                        <div>
                            <i className="fa fa-building" >
                                <p>Organizations:  {this.state.orgs}</p>
                                <ul>
                                    {this.state.orgs_list.map((k, i) => <li key={i}>{k}</li>)}
                                </ul>
                            </i>

                        </div>}
                        
                        {!this.state.collapsed && <div>
                            <i className="fa fa-user" >
                                <p>Persons:  {this.state.persons}</p>
                                <ul>
                                    {this.state.persons_list.map((k, i) => <li key={i}>{k}</li>)}
                                </ul>
                            </i>

                        </div>}
                    </div>}

                </div>

            </div>
        );
    }
}

export default GridElement;
