import React from "react";

import 'font-awesome/css/font-awesome.min.css'
import './AppPage.css'
import GridElement from '../GridElement/GridElement'
import Loader from "../../components/Loader/Loader";
import ReactPaginate from 'react-paginate';


export class AppPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: null,
            label: null,
            label_tmp: "Location",
            data: null,
            length: null,
            newest: null,
            oldest: null,
            article_stats: null,
            loading: false,
            current_page: 0,
            total_pages: 10,
            page_data: null,
            items_per_page: 10
        }
    }

    fetch_subset = async (keyword) => {
        const url = `https://mediapot.iit.demokritos.gr:2005/graph/graph-subset/${this.state.label_tmp}/${keyword}`

        fetch(url)
            .then(response => response.json())
            .then(data => this.setState({ 
                data: data.graph_subset, 
                length: data.length, 
                oldest: data.oldest, 
                newest: data.newest, 
                keyword: keyword,
                loading: false,
                total_pages: Math.ceil(data.length/this.state.items_per_page),
                page_data: data.graph_subset.slice(0,(0 + this.state.items_per_page)),
                current_page: 0 
            }))
    }

    handle_key_down = (e) => {
        if (e.key === "Enter") {
            this.setState({ keyword: e.target.value, label: this.state.label_tmp, loading: true })
            this.fetch_subset(e.target.value)
        }
    }

    change_label = (e) => {
        this.setState({ label_tmp: e.target.innerText })
    }

    handle_page_change = (selected_page) => {
        const start_offset = selected_page["selected"] * this.state.items_per_page
        const end_offset =  (this.state.items_per_page * (selected_page["selected"] +1 ))
        this.setState({current_page: selected_page["selected"], page_data: this.state.data.slice(start_offset, end_offset)})
    }

    scroll_to_top = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    render() {
        return (
            <div className="page-box">
                <div className="search-box">
                    <div className="label-area">
                        <p>Select a label</p>
                        <div id="Location" className={this.state.label_tmp === "Location" ? "label_selected" : "label"} onClick={this.change_label}><i className="fa fa-map-marker" > <p>Location</p></i></div>
                        <div id="Person" className={this.state.label_tmp === "Person" ? "label_selected" : "label"} onClick={this.change_label}><i className="fa fa-user" ><p>Person </p> </i></div>
                        <div id="Org" className={this.state.label_tmp === "Org" ? "label_selected" : "label"} onClick={this.change_label}><i className="fa fa-building" ><p>Org</p></i></div>
                    </div>
                            
			<div className="search-area">
                                       		<p>Enter a search keyword</p>
                        <input onKeyDown={(e) => {
                            if (e.key === "Enter")
                                this.handle_key_down(e)
                        }} name="myInput" />
			            
			
		</div>
                </div>

                {this.state.loading && <Loader isloading={this.state.loading} />}
                {this.state.keyword && <div className="search-box-title">
                    {this.state.length > 0 ? `${this.state.length} articles from ${this.state.oldest} to ${this.state.newest} mention  (${this.state.label}: ${this.state.keyword})` : `No results for  (${this.state.label}: ${this.state.keyword})`}
                    </div>}
                <div className="grid-container">
                    {this.state.data && <div className="custom-pagination"><ReactPaginate 
                    pageCount={this.state.total_pages}
                    onPageChange={this.handle_page_change}
                    previousLabel={"< previous"}
                    nextLabel={"next >"}
                    breakLabel={"..."}
                    ageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                    /></div>}
                    {this.state.length > 0 && <div className="page-indicator"><p>Page: {this.state.current_page + 1}/{this.state.total_pages}</p></div>}
                    {this.state.page_data && this.state.page_data.map((d, i) =>
                        <div><GridElement key={`${d["connected_node_element_id"]}_${i}`} element={d} />
                        </div>                                           
                    )
                    }
                    {this.state.length > 0 && <div className="scroll-top" onClick={this.scroll_to_top}>Scroll to top <i className="fa fa-angle-up"/></div>}
                </div>
            </div>
        );
    }
}

export default AppPage;
